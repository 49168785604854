import $ from 'jquery';

window.$ = window.jQuery = $;


import AOS from 'aos';

require('./bootstrap');
require('owl.carousel')
require('slick-carousel')
require('aos/dist/aos.css');

//|--------------------------------------------------------------------------
//| Load video

$(document).ready(function () {
    setTimeout(function () {
        $('.js_video-load').removeClass('opacity-0').addClass('__animate-fadeFromBottom');
    }, 500);
});
AOS.init({
    delay: 125,
});

//Slider
$('.successStories-slider').slick({
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fa-light fa-arrow-left-long"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fa-light fa-arrow-right-long"></i></button>',
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});
//menu
$(window).scroll(function () {
    if ($(window).scrollTop() > 1) {
        $(".header-wrap").addClass('sticky');
    } else {
        $(".header-wrap").removeClass('sticky');
    }
});

//about-banner-slider
$('.about-banner-slider').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true
});

$('nav.main-nav > ul > li').find('ul').parent().append('<span class="collapse-icon"></span>');
$('.m-toggle').click(function () {
    $('.main-nav').slideToggle();
    $('nav.main-nav > ul > li > ul').slideUp();
    $('nav.main-nav > ul > li .collapse-icon').removeClass('active');
});

$('nav.main-nav .close-btn').click(function () {
    $('.main-nav').slideUp();
    $('nav.main-nav > ul > li > ul').slideUp();
    $('nav.main-nav > ul > li .collapse-icon').removeClass('active');
});

$('nav.main-nav > ul > li .collapse-icon').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $(this).siblings('ul').slideToggle();
});

//btn-your-challenge
$('.btn-your-challenge').click(function (e) {
    e.preventDefault();
    $(this).parents('.your-challenge-div').addClass('active');
})
$('.your-challenge-div .clsbtn').click(function () {
    $(this).parents('.your-challenge-div').removeClass('active');
})

$('.subhero_slider').slick({
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1250,
    prevArrow: '<button type="button" class="slick-prev"><i class="fa-light fa-arrow-left-long"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fa-light fa-arrow-right-long"></i></button>',
    arrows: true,
    dots: true,
});

$('.case_slider').slick({
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1250,
    prevArrow: '<button type="button" class="slick-prev"><i class="fa-light fa-arrow-left-long"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fa-light fa-arrow-right-long"></i></button>',
    arrows: true,
    dots: true,
});

$('.hero-slider').slick({
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fa-light fa-arrow-left-long"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fa-light fa-arrow-right-long"></i></button>',
    dots: true,
    draggable: false,
});

$('.js_growthSlider').slick({
    slidesToShow: 3,
    arrows: false,
    dots: true,
    infinite: true,
    draggable: true,
    swipe: true,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 2500,
    swipeToSlide: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fa-light fa-arrow-left-long"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fa-light fa-arrow-right-long"></i></button>',
    responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});

